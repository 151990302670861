import { DeepPartial } from './DeepPartial';
import { DeepPartialObject } from './DeepPartialObject';
import { isEmptyObject } from './isEmptyObject';
import { isEmptyValue } from './isEmptyValue';
import { isNonEmptyValue } from './isNonEmptyValue';
import { isPlainObject } from './isPlainObject';

/**
 * Recursively removes properties with empty values from an object.
 *
 * @template T - The type of the input object.
 * @param {T | undefined} obj - The object from which to remove empty values.
 * @returns {DeepPartial<T> | undefined} - A new object with empty values removed, or `undefined` if the input object is empty.
 *
 * @remarks
 * - An empty value is defined by the `isEmptyValue` function.
 * - A plain object is determined by the `isPlainObject` function.
 * - Non-empty values are determined by the `isNonEmptyValue` function.
 * - The result is a deep partial of the input object type.
 */
export const withoutEmptyValues = <T>(
    obj: T | undefined
): DeepPartial<T> | undefined => {
    if (isEmptyValue(obj)) {
        return undefined;
    }

    if (!isPlainObject(obj)) {
        return obj as DeepPartial<T>;
    }

    type KeyType = keyof typeof obj;

    const result = Object.keys(obj).reduce(
        (acc: DeepPartialObject<T>, k: KeyType) => {
            const property = obj[k as KeyType] as unknown;
            const propertyValue = isPlainObject(property)
                ? withoutEmptyValues(property)
                : property;

            if (isNonEmptyValue(propertyValue)) {
                return { ...acc, [k]: propertyValue };
            }
            return acc;
        },
        {} as DeepPartialObject<T>
    ) as DeepPartial<T>;

    return !isEmptyObject(result) ? result : undefined;
};
